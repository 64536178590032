import type { ReadAccrualInvoice } from "@silexpert/core";

export type FileInError = {
  name: string;
};

export const useAnnotateStore = defineStore("annotateAfterImport", {
  state: () => {
    return {
      importedTransactions: null as ReadAccrualInvoice[] | null,
      isExpenseNote: false as boolean,
      status: null as "pending" | "finished" | null,
      totalFileToUpload: 0,
      totalFilesInError: null as FileInError[] | null,
      displayErrorModale: false as boolean,
      forceDisplayErrorModale: false as boolean,
    };
  },
  getters: {},
  actions: {
    reset() {
      this.importedTransactions = null;
      this.isExpenseNote = false;
      this.status = null;
      this.totalFileToUpload = 0;
      this.totalFilesInError = null;
      this.displayErrorModale = false;
      this.forceDisplayErrorModale = false;
    },
  },
});
