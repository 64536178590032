export enum AnnotationErrorType {
  AMOUNT_ALL_TAX_INCLUDED = 1,
  CATEGORY = 2,
  VAT = 3,
}

export type AnnotationError = {
  id: number;
  index?: number;
  type: AnnotationErrorType;
  message: string;
};
